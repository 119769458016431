import { tv, type VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		base: 'flex items-center text-gray-200/70 gap-6 w-full',
		border: 'border-t border-inherit w-full',
	},
})

interface DividerProps extends VariantProps<typeof styles> {
	className?: string
}

const { base, border } = styles()

export const Divider = ({ className }: DividerProps) => (
	<div className={`${className} ${base()}`} aria-hidden='true'>
		<div className={border()}></div>
		<div>or</div>
		<div className={border()}></div>
	</div>
)

import { create } from 'zustand'

type Fields = {
	[fieldName: string]: {
		value: string
		errors: string[]
	}
}

export interface useAuthFormStoreInterface {
	fields: Fields
	setFieldValue: (node: keyof Fields, value: string) => void
	setFieldErrors: (node: keyof Fields, value: string[]) => void
}

export const useAuthFormStore = create<useAuthFormStoreInterface>()(set => ({
	fields: {},
	setFieldValue: (node, value) =>
		set(state => ({
			fields: {
				...state.fields,
				[node]: {
					errors: state?.fields?.[node]?.errors || [],
					value: value,
				},
			},
		})),
	setFieldErrors: (node, value) =>
		set(state => ({
			fields: {
				...state.fields,
				[node]: {
					value: state.fields?.[node]?.value || '',
					errors: value,
				},
			},
		})),
}))

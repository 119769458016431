import { type IconProps } from '_/ui/icons/icon.types'
import IconUse from '_/ui/icons/use'
import { tv } from 'tailwind-variants'

export const styles = tv({
	base: 'fill-primary-neutral',
})

export const NotViewIcon = ({ className, ...rest }: IconProps) => (
	<IconUse id='offIcon' width={24} height={26} className={styles({ class: className })} {...rest} />
)

import { tv, type VariantProps } from 'tailwind-variants'
import UseIcon from '_/ui/icons/use'
import { Response } from '_/ui/field/response'
import { useState } from 'react'

const styles = tv({
	slots: {
		base: 'transition-transform duration-300 w-full top-0 absolute rounded-lg border border-red-500 text-red-500 before:w-full before:h-full before:absolute before:top-0 before:left-0 before:bg-white before:rounded-lg',
		wrapper: 'flex items-center gap-4 bg-red-500/10 relative py-2 px-4',
		response: 'text-start font-normal m-0',
	},
})

const { base, wrapper, response } = styles()

interface FormErrorProps extends VariantProps<typeof styles> {
	className?: string
	error: string | null | string[] | string[][]
	animationClss?: string
}

export const FormError = ({ className, error, animationClss = 'fade-slide-up' }: FormErrorProps) => {
	const [showError, setShowError] = useState<boolean>(!!error?.length)
	let animation = ''
	if (showError) {
		animation = animationClss
	}
	setTimeout(() => {
		setShowError(false)
		// eslint-disable-next-line no-magic-numbers
	}, 5000)

	return (
		<>
			{showError && (
				<div className={`${base()} ${className} ${animation}`}>
					<div className={wrapper()}>
						<UseIcon
							id='close'
							width={20}
							height={20}
							className='fill-inherit cursor-pointer min-w-2 h-2'
							onClick={() => setShowError(!showError)}
						/>

						<Response className={response()} themeColor='invalid'>
							{error}
						</Response>
					</div>
				</div>
			)}
		</>
	)
}

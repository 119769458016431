import { tv, type VariantProps } from 'tailwind-variants'

export const styles = tv({
	base: 'inline-block text-gray-700 text-base font-normal mb-2 cursor-pointer',
})

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement>, VariantProps<typeof styles> {
	className?: string
	children: React.ReactNode
}

export const Label = ({ className, children, ...rest }: LabelProps) => (
	<label className={styles({ class: className })} {...rest}>
		{children}
	</label>
)

import { z } from 'zod'

export const endpointSchema = z.string().refine(e => e.startsWith('/'))

export const validEndpoint = (baseApi: string, endpoint: string) => {
	const endpointParsed = endpointSchema.safeParse(endpoint)

	if (!endpointParsed.success) {
		return `${baseApi}/${endpoint}`
	}

	return `${baseApi}${endpointParsed.data}`
}

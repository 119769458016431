'use client'
import { tv, type VariantProps } from 'tailwind-variants'
import Button from '_/ui/button'
import IconUse from '_/ui/icons/use'
import { Divider } from './divider'
import AuthRedirect from '_/services/auth/redirect.fetch'

export const styles = tv(
	{
		slots: {
			base: 'mt-6 w-full flex flex-row gap-4 items-center mb-4',
			button: ' hover:border-primary-light p-3',
			buttonText: 'text-gray-500 font-semibold text-base hidden',
			icon: 'h-6 w-6',
			label: 'text-gray-500 font-semibold',
		},
		variants: {
			size: {
				md: {
					base: 'flex-col mb-4',
					buttonText: 'inline',
					label: 'hidden',
					icon: 'h-5 w-5',
					button: 'py-3 px-4 w-full',
				},
			},
		},
	},
	{ responsiveVariants: ['md'] },
)

interface OptionsProps extends VariantProps<typeof styles> {
	className?: string
	isLoginOption: boolean
}

const { base, button, icon, buttonText, label } = styles({
	size: {
		md: 'md',
	},
})

export const Options = ({ className, isLoginOption }: OptionsProps) => {
	const isLogin = isLoginOption ? 'Login' : 'Sign up'

	return <div className={base({ class: className })} />

	return (
		<>
			<div className={base({ class: className })}>
				<span className={label()}>{isLogin} with</span>
				<Button
					onClick={() => AuthRedirect({ provider: 'google' })}
					themeColor='outline'
					className={button()}
					aria-label={`${isLogin} with google'`}
				>
					<IconUse id='google' className={icon()} />
					<span className={buttonText()}>{isLogin} with Google</span>
				</Button>
			</div>
			<Divider />
		</>
	)
}

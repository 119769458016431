'use client'
import { AuthLinks } from '_/enums'
import Button from '_/ui/button'
import { Input } from '_/ui/field/input.pure'
import { Label } from '_/ui/field/label'
import Link from 'next/link'
import { tv, type VariantProps } from 'tailwind-variants'

import { signInAction } from '_/services/auth/sign-in.action'

import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { FieldFeedback, PasswordField, WithStore } from './field-with-store'
// @ts-expect-error because it's only a TypeScript issue due to the lack of type definition.
import { useFormStatus } from 'react-dom'
import { FormError } from './form-error'
import { triggerCreatePasswordEmailAction } from '_/services/auth/create-password/trigger-create-password-email.action'

export const styles = tv({
	slots: {
		base: 'flex flex-col grow text-gray-700 mt-3',
		field: 'mb-1.5 relative',
		response: 'text-start',
		checkboxWrapper: 'flex items-center gap-x-2',
		extraField: 'flex items-center justify-between mb-9 lg:mb-0',
		forgotLink: 'font-bold text-primary-neutral hover:text-secondary-neutral transition-color duration-300 underline',
		button: 'mt-auto lg:mb-6 w-full',
		responseIconWrapper: 'absolute right-6 top-1/2 -translate-y-1/2 flex items-center gap-2',
	},
})

interface SignInProps extends React.HTMLAttributes<HTMLFormElement>, VariantProps<typeof styles> {
	className?: string
}

const { field, extraField, forgotLink, button, base } = styles()

interface SignInProps extends React.HTMLAttributes<HTMLFormElement> {
	className?: string
}

export function SubmitButton({ className }: { className?: string }) {
	const { pending } = useFormStatus()
	return (
		<Button className={button({ class: className })} type='submit' aria-disabled={pending} disabled={pending}>
			Login
		</Button>
	)
}

const timeoutToClearErrors = 5000

export const SignIn = ({ className }: SignInProps) => {
	const router = useRouter()
	const [formError, setFormError] = useState<unknown>(null)

	const userHasToCreatePassword = async (formData: FormData) => {
		const data = await triggerCreatePasswordEmailAction(formData)
		if (!data?.error) {
			router.push(AuthLinks.CreateYourPassword)
		}
	}

	const onSubmit = async (formData: FormData) => {
		const data = await signInAction(formData)
		if (data?.user) {
			router.push('/')
		}

		if (data?.error) {
			setFormError(data?.error)
			setTimeout(() => setFormError(null), timeoutToClearErrors)
		}

		if (data.code && data.code === 'FV01') {
			setFormError('')
			router.push(AuthLinks.ConfirmAccount)
		}

		if (data.code && data.code === 'FV02') {
			setFormError('')
			userHasToCreatePassword(formData)
		}
	}

	return (
		<form action={onSubmit} className={base({ class: className })}>
			<div className={field({ class: 'lg:mb-2' })}>
				<Label>Email address</Label>
				<WithStore>
					<Input placeholder='Your email' name='email' />
				</WithStore>
				<FieldFeedback name='email' />
			</div>
			<div className={field({ class: 'lg:mb-2' })}>
				<Label>Password</Label>
				<WithStore>
					<PasswordField />
				</WithStore>
				<FieldFeedback name='password' />
			</div>

			<div className={extraField()}>
				<Link className={forgotLink()} href={AuthLinks.RecoveryAccount} aria-label='Start the recovery account process'>
					Forgot password?
				</Link>
			</div>

			<div className='relative mt-auto'>
				{formError ? <FormError error={formError as string} /> : null}
				<SubmitButton className={formError === '' ? 'opacity-20 pointer-events-none' : ''} />
			</div>
		</form>
	)
}

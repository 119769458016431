import { tv, type VariantProps } from 'tailwind-variants'

export const styles = tv({
	base: 'text-sm font-semibold block text-right mt-1',
	variants: {
		themeColor: {
			medium: 'text-yellow-500',
			invalid: 'text-red-500',
			success: 'text-green-500',
		},
	},
})

interface SpanProps extends VariantProps<typeof styles>, React.HTMLAttributes<HTMLSpanElement> {
	className?: string
	children: React.ReactNode
}

// Interface for field response: password, email...
export const Response = ({ className, children, themeColor, ...rest }: SpanProps) => (
	<div {...rest} className={styles({ class: className, themeColor })}>
		{children}
	</div>
)

import { Slot } from '@radix-ui/react-slot'
import { tv, type VariantProps } from 'tailwind-variants'

export const styles = tv({
	base: 'text-lg cursor-pointer leading-[1.4] rounded font-bold relative flex items-center justify-center space-x-2 border-0 border-primary-neutral hover:border-gray-700',
	variants: {
		themeColor: {
			primary: 'text-primary-light bg-primary-neutral hover:bg-gray-700 transition-colors duration-300 ease-in-out',
			secondary:
				'text-primary-neutral border-2 hover:bg-gray-700 hover:text-gray-100 transition-colors duration-300 ease-in-out',
			tertiary: 'bg-transparent hover:bg-transparent text-primary-neutral hover:text-secondary-neutral p-0',
			white: 'bg-white hover:bg-white text-primary-neutral transition-colors duration-300 ease-in-out',
			transparent: 'bg-white/20 hover:bg-white/40 text-gray-300 transition-colors duration-300 ease-in-out',
			link: 'bg-transparent hover:bg-transparent p-0 text-white underline leading-none',
			linkPrimary: 'bg-transparent hover:bg-transparent p-0 text-primary-neutral underline leading-none',
			outline: 'bg-white border border-primary-light flex gap-x-2 gap-4',
			backButton:
				'bg-gray-700 text-gray-100 hover:bg-secondary-neutral hover:text-gray-700 transition-[colors, transform] duration-300 ease-in-out',
		},
		disabled: {
			true: 'opacity-20 pointer-events-none',
		},
		size: {
			sm: 'py-2 px-6',
			md: 'py-3 px-4',
			lg: 'p-6',
		},
		withShadow: {
			true: 'shadow-[5px_5px_20px_0px_rgba(0,0,0,0.15);]',
		},
		fluid: {
			false: 'w-auto float-left px-2.5',
		},
	},
	defaultVariants: {
		themeColor: 'primary',
		size: 'md',
	},
})

export interface ButtonProps extends VariantProps<typeof styles>, React.ButtonHTMLAttributes<HTMLButtonElement> {
	asChild?: boolean
	children?: React.ReactNode
	className?: string
}

const Button = ({ asChild, children, className, themeColor, withShadow, size, fluid, ...buttonProps }: ButtonProps) => {
	const Comp = asChild ? Slot : 'button'

	const resolvedClass = styles({
		themeColor,
		withShadow,
		fluid,
		size,
		disabled: buttonProps.disabled,
		class: className,
	})

	return (
		<Comp {...buttonProps} className={resolvedClass}>
			{children}
		</Comp>
	)
}

export default Button

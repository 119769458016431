import { type Method, type FetchERROR, type Headers, type Body } from '_/interfaces'

import { validEndpoint } from '_/utils/valid-endpoint'

export type FetcherOptions = {
	headers?: Headers
	method?: Method
	endpoint: string
	body?: Body
}

export const fetcherCSR = async <T>(options: FetcherOptions): Promise<T | FetchERROR> => {
	try {
		const api = process.env.NEXT_PUBLIC_BASE_API as string
		const endpoint = validEndpoint(api, options.endpoint)

		const response = await fetch(endpoint, {
			method: options.method ?? 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				...(options.headers ?? {}),
			},
			next: { revalidate: 0 },
			...(options.body ? { body: JSON.stringify(options.body) } : {}),
		})

		const data = await response.json()

		return data
	} catch (error) {
		throw error as FetchERROR
	}
}

'use client'

import * as AuthSchema from '_/services/auth/auth.scheme'
import { Input } from '_/ui/field/input.pure'
import { Response } from '_/ui/field/response'
import { ViewIcon } from '_/ui/field/view-icon'
import React, { useEffect, useState } from 'react'
import { tv } from 'tailwind-variants'
import { useShallow } from 'zustand/react/shallow'
import { useAuthFormStore } from './form.store'
import { NotViewIcon } from '_/ui/field/not-view-icon'

const styles = tv({
	slots: {
		base: 'flex flex-col grow text-gray-700 mt-3',
		field: 'mb-1.5',
		fieldWrapper: 'lg:mb-2',
		response: 'text-start',
		checkboxWrapper: 'flex items-center gap-x-2',
		extraField: 'flex items-center justify-between',
		forgotLink: 'font-bold text-primary-neutral hover:text-secondary-neutral transition-color duration-300 underline',
		button: 'mt-auto lg:mb-6 w-full',
		responseIconWrapper: 'absolute right-6 top-1/2 -translate-y-1/2 flex items-center gap-2',
	},
})

const { fieldWrapper, responseIconWrapper, response } = styles()

const getThemeColors = (hasValue: boolean, hasError: boolean) => {
	if (hasValue && hasError) {
		return 'invalid'
	}

	if (hasValue && !hasError) {
		return 'success'
	}

	return 'default'
}

export const FieldWrapper = ({ children }: { children: React.ReactElement }) => (
	<div className={fieldWrapper()}>{children}</div>
)

const setFieldValue = useAuthFormStore.getState().setFieldValue
const setFieldErrors = useAuthFormStore.getState().setFieldErrors

export const WithStore = ({ children }: { children: React.ReactElement }) => {
	const { name } = children.props
	const schemaName = name as keyof typeof AuthSchema
	const schema = AuthSchema[schemaName]

	const value = useAuthFormStore(useShallow(state => state.fields?.[name]?.value))
	const errors = useAuthFormStore(useShallow(state => state.fields?.[name]?.errors))

	const onInputChange = (inputName: string, newValue: string) => {
		setFieldValue(inputName, newValue)

		const result = schema.safeParse(newValue)

		if (result.success && value !== '') {
			setFieldErrors(inputName, [])
		}

		if (!result.success) {
			const { _errors } = result.error.format()
			setFieldErrors(inputName, _errors)
		}
	}

	return React.cloneElement(children as React.ReactElement, {
		onInputChange,
		value: value ?? (children.props.value || ''),
		themeColor: getThemeColors(!!value, !!errors?.length),
	})
}

export const PasswordLoginField = ({ value }: { value?: string | undefined }) => {
	const [showPass, setShowPassword] = useState(false)
	const [viewPasswordBtn, setViewShowPassButton] = useState(false)

	const storePasswordValue = useAuthFormStore(useShallow(state => state.fields?.['password']?.value))
	useEffect(() => {
		if (storePasswordValue && !!storePasswordValue.length) {
			setViewShowPassButton(true)
		} else {
			setViewShowPassButton(false)
		}
	}, [viewPasswordBtn, storePasswordValue])

	return (
		<div className='relative'>
			<Input placeholder='Your password' name='password' value={value} type={showPass ? 'text' : 'password'} />
			<div className={responseIconWrapper()}>
				{viewPasswordBtn && (
					<button type='button' onClick={() => setShowPassword(state => !state)}>
						{showPass ? <ViewIcon className='relative' /> : <NotViewIcon className='relative' />}
					</button>
				)}
			</div>
		</div>
	)
}

export const PasswordField = ({ value }: { value?: string | undefined }) => {
	const [showPass, setShowPassword] = useState(false)
	const [viewPasswordBtn, setViewShowPassButton] = useState(false)

	const storePasswordValue = useAuthFormStore(useShallow(state => state.fields?.['password']?.value))
	useEffect(() => {
		if (storePasswordValue && !!storePasswordValue.length) {
			setViewShowPassButton(true)
		} else {
			setViewShowPassButton(false)
		}
	}, [viewPasswordBtn, storePasswordValue])

	return (
		<div className='relative'>
			<WithStore>
				<Input placeholder='Min 8 characters' name='password' value={value} type={showPass ? 'text' : 'password'} />
			</WithStore>
			<div className={responseIconWrapper()}>
				{viewPasswordBtn && (
					<button type='button' onClick={() => setShowPassword(state => !state)}>
						{showPass ? <ViewIcon className='relative' /> : <NotViewIcon className='relative' />}
					</button>
				)}
			</div>
		</div>
	)
}

export const ConfirmPasswordField = () => {
	const [showPass, setShowPassword] = useState(false)
	const [viewPasswordBtn, setViewShowPassButton] = useState(false)

	const storePasswordValue = useAuthFormStore(useShallow(state => state.fields?.['passwordConfirmation']?.value))
	useEffect(() => {
		if (storePasswordValue && !!storePasswordValue.length) {
			setViewShowPassButton(true)
		} else {
			setViewShowPassButton(false)
		}
	}, [viewPasswordBtn, storePasswordValue])

	return (
		<div className='relative'>
			<WithStore>
				<Input placeholder='Min 8 characters' name='passwordConfirmation' type={showPass ? 'text' : 'password'} />
			</WithStore>
			<div className={responseIconWrapper()}>
				{viewPasswordBtn && (
					<button type='button' onClick={() => setShowPassword(state => !state)}>
						{showPass ? <ViewIcon className='relative' /> : <NotViewIcon className='relative' />}
					</button>
				)}
			</div>
		</div>
	)
}

export const FieldFeedback = ({ name }: { name: string }) => {
	const errors = useAuthFormStore(useShallow(state => state.fields?.[name]?.errors))

	if (!errors) {
		return null
	}

	return (
		<div>
			<Response className={response()} themeColor='invalid'>
				{/* :holding back tears
					element structure to avoid error: Failed to execute 'removeChild' on 'Node'
				*/}
				{!!errors.length && <span>{errors}</span>}
			</Response>
		</div>
	)
}

export enum AuthPanel {
	Title = 'Your stress free vacation starts here.',
	Text = '',
}

export enum SignUpSuccessPanel {
	Title = 'Confirmed email',
	Text = '',
}

export enum ChangedEmailPanel {
	Title = 'Changed email',
	Text = '',
}

export enum RecoveryAccountPanel {
	Title = 'Reset your password',
	Text = '',
}

export enum CreatePasswordPanel {
	Title = 'Create your password',
	Text = "You've already requested a quote, please create your password to access the dashboard and follow progress more closely.",
}

export enum AuthImages {
	Auth = '/imgs/auth/auth.webp',
	Recovery = '/imgs/auth/reset.webp',
	Success = '/imgs/auth/success.webp',
}

export enum AuthLinks {
	SignIn = '/auth/sign-in',
	RecoverySuccess = '/auth/recovery-success',
	RecoveryAccount = '/auth/recovery-account',
	CheckEmail = '/auth/check-email',
	ResetPassword = '/auth/reset-password',
	SignUp = '/auth/sign-up',
	AccountCreated = '/auth/account-created',
	AccountReactivated = '/auth/account-reactivated',
	ConfirmedEmail = '/auth/confirmed-email',
	CreatedPassword = '/auth/created-password-success',
	ConfirmAccount = '/auth/confirm-account',
	CreatePassword = '/auth/create-password',
	CreateYourPassword = '/auth/create-your-password',
	ChangedEmail = '/auth/changed-email',
	VerifyChangedEmailRedirect = '/auth/verify-email-change-redirect',
	VerifyAccount = '/auth/verify-account',
}

export enum FamviaSite {
	Destination = `/destinations`,
	LatestNews = `/news`,
	Vacation101 = `/vacation-101/`,
	UsingTravelAdvisor = `/why-you-should-use-a-travel-advisor/`,
	Earn = `/rewards-pass/`,
	AboutUs = `/about-us/`,
}

export enum SidebarLinks {
	Upcoming = '/trips/upcoming',
	Completed = '/trips/completed',
	Pending = '/quotes/pending',
	StartHere = '/quotes/start-here',
	Balance = '/rewards-pass/balance',
	Subscription = '/rewards-pass/subscription',
	AccountDetails = '/account/travel-profile',
	AccountLogin = '/account/login-and-security',
	AccountReferralCode = '/account/referral-code',
}

export enum SidebarSegments {
	Upcoming = 'upcoming',
	Completed = 'completed',
	Pending = 'pending',
	StartHere = 'start-here',
	Balance = 'rewards-balance',
	Subscription = 'rewards-subscription',
	AccountDetails = 'travel-profile',
	AccountLogin = 'login-and-security',
	AccountReferralCode = 'referral-code',
}

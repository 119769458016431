import * as z from 'zod'

const minCharacters = 8
const maxCharacters = 64

const passwordSchema = z
	.string()
	.min(minCharacters, 'Password must be at least 8 characters long. ')
	.max(maxCharacters, 'Password must not exceed 64 characters. ')
	.refine(
		passwordData => {
			const hasUpperCase = /[A-Z]/.test(passwordData)
			const hasLowerCase = /[a-z]/.test(passwordData)
			const hasNumber = /[0-9]/.test(passwordData)
			const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:<>,.?~\\-]/.test(passwordData)

			return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && passwordData.length >= minCharacters
		},
		{
			message:
				'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character. ',
		},
	)

const twoWordsValidator = (value: string) => {
	const regex = /^[A-Za-z]+\s[A-Za-z]+$/
	return regex.test(value)
}

export const name = z.string().refine(twoWordsValidator, {
	message: 'Name must have first and last name',
})
export const email = z.string().email()
export const firstName = z.string().min(1, { message: 'First name is required. ' })
export const lastName = z.string().min(1, { message: 'Last name is required. ' })
export const password = passwordSchema
export const passwordConfirmation = passwordSchema
export const rememberMe = z.string().optional()
export const checkbox = z.literal(true)

export const AuthSchema = z.object({
	name: name,
	firstName: firstName,
	lastName: lastName,
	email: email,
	password: password,
	passwordConfirmation: passwordConfirmation,
	rememberMe: rememberMe,
	isChecked: checkbox,
})

export const RegisterSchema = z
	.object({
		firstName: firstName,
		lastName: lastName,
		email: email,
		password: password,
		passwordConfirmation: passwordConfirmation,
	})
	.superRefine((passwords, ctx) => {
		if (passwords.passwordConfirmation !== passwords.password) {
			ctx.addIssue({
				code: 'custom',
				message: 'Passwords do not match.',
			})
		}
	})

export type AuthType = z.infer<typeof AuthSchema>

import { type IconProps } from './icon.types'

const iconSizeDefault = 16

export const IconUse = ({ id, width = iconSizeDefault, height = iconSizeDefault, ...rest }: IconProps) => (
	<svg className={`icon ${id}`} height={height} width={width} aria-hidden='true' {...rest}>
		<use xlinkHref={`#${id}`} x='0' y='0' />
	</svg>
)

export default IconUse

import { type IconProps } from '_/ui/icons/icon.types'
import IconUse from '_/ui/icons/use'
import { tv } from 'tailwind-variants'

export const styles = tv({
	base: 'fill-primary-neutral',
})

export const ViewIcon = ({ className, ...rest }: IconProps) => (
	<IconUse id='view' width={21} height={18} className={styles({ class: className })} {...rest} />
)

import { type FetchResponse, type FetchERROR } from '_/interfaces'
import { fetcherCSR } from '_/services/fetcher.csr'
import { ResponseSchema, type ResponseType } from './redirect.scheme'
import { type ErrorType } from './error.scheme'
import { ZodError } from 'zod'
import { errorTracker } from '_/services/error-tracker'

type Props = {
	provider: string
}

type ERROR = ZodError<ErrorType>
type Response = Promise<FetchResponse<ResponseType, ERROR>>

const AuthRedirect = async ({ provider }: Props): Response => {
	try {
		const dataResponse = await fetcherCSR<ResponseType>({
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			endpoint: 'api/users/auth0',
			body: { provider: provider },
		})
		const response = ResponseSchema.safeParse(dataResponse)
		if (!response.success) {
			const requestError = dataResponse as FetchERROR
			throw {
				dataFormatError: response.error,
				requestError: requestError.errors,
			}
		} else {
			window.location.href = response.data.auth0AuthorizeURL
		}

		return {
			data: response.data,
			error: null,
		}
	} catch (error) {
		const errorResponse = error as unknown as ERROR
		errorTracker(errorResponse, 'sign in redirect')

		throw {
			data: {} as ResponseType,
			error: errorResponse,
		}
	}
}

export default AuthRedirect

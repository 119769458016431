'use client'
import { tv, type VariantProps } from 'tailwind-variants'

export const styles = tv({
	base: 'text-base placeholder-gray-200 placeholder font-normal rounded border px-6 py-3 w-full focus:ring-2 focus:ring-primary-neutral [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
	variants: {
		disabled: {
			true: 'opacity-50 pointer-events-none',
		},
		themeColor: {
			default: 'text-gray-300 border-primary-light hover:border-gray-700',
			invalid: 'text-red-500 border-red-500',
			success: 'text-primary-neutral border-primary-neutral',
		},
	},
	defaultVariants: {
		themeColor: 'default',
	},
})

interface InputProps extends VariantProps<typeof styles>, React.InputHTMLAttributes<HTMLInputElement> {
	id?: string
	name: string
	className?: string
	label?: string
	onInputChange?: (inputName: string, newValue: any, e?: React.ChangeEvent<HTMLInputElement>) => void
}

export const Input = ({ className, disabled, themeColor, onInputChange, label, ...rest }: InputProps) => {
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onInputChange) {
			onInputChange(rest.name, e.target.value, e)
		}
	}

	return <input {...rest} className={styles({ class: className, themeColor, disabled })} onChange={handleInputChange} />
}
